import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_button_more = _resolveComponent("tm-button-more")!

  return (_openBlock(), _createBlock(_component_tm_button_more, {
    "btn-size": _ctx.btnSize,
    "icon-size": _ctx.iconSize,
    horizontal: "",
    flat: false
  }, null, 8, ["btn-size", "icon-size"]))
}