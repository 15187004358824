import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_login_history_filter = _resolveComponent("login-history-filter")!
  const _component_login_history_table = _resolveComponent("login-history-table")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "userLogins",
    "modal-title": "Recent workspace logins",
    "no-footer": "",
    size: "xLarge"
  }, {
    "modal-content": _withCtx(() => [
      _createVNode(_component_login_history_filter, {
        search: _ctx.search,
        "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
        "search-placeholder": "Search logins",
        class: "mb-2"
      }, null, 8, ["search"]),
      _createVNode(_component_login_history_table, {
        headers: _ctx.historyHeaders,
        items: _ctx.filteredTableItems,
        "item-key": "id",
        "items-per-page": 5,
        "hide-actions": ""
      }, null, 8, ["headers", "items"])
    ]),
    _: 1
  }))
}