
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import FilterCheckbox from '@/components/shared/filters/FilterCheckbox.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import FilterPanelButton from '@/components/shared/filters/FilterPanelButton.vue'
import FilterButton from '@/components/shared/filters/FilterButton.vue'
import { capitalize } from '@/services/utils'

export default defineComponent({
  name: 'DropdownFilterCheckbox',
  components: { TmDropdown, FilterPanelButton, FilterCheckbox, FilterButton },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    class: {
      type: String,
    },
    removable: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
    },
    name: {
      type: String,
    },
    icon: {
      type: String,
    },
    allText: {
      type: String,
      default: 'All',
    },
    btnSize: {
      type: String as PropType<'default' | 'small' | 'large' | 'xLarge'>,
      default: 'large',
    },
    optionLabel: {
      type: String,
      default: 'label',
    },
    customGetLabel: {
      type: Function,
    },
    panelView: {
      type: Boolean,
    },
    dropdownClass: {
      type: String,
    },
    buttonClass: {
      type: String,
    },
    valueClass: {
      type: String,
    },
    maxShow: {
      type: Number,
      default: 1,
    },
    noFit: {
      type: Boolean,
    },
  },
  emits: ['update:model-value'],
  setup(props) {
    const valueStr = computed(() => {
      const defaultGetLabel = (item: any) => {
        return typeof item === 'string' ? capitalize(item) : capitalize(item[props.optionLabel])
      }

      const getLabel = props.customGetLabel || defaultGetLabel
      if (props.modelValue.length === props.options.length || props.modelValue.length === 0) {
        return props.allText
      }
      if (props.modelValue.length < props.maxShow + 1) {
        return props.modelValue.reduce((acc, val, index) => {
          if (index === 0) return getLabel(val)
          return `${acc}, ${getLabel(val)}`
        }, '')
      }
      return props.modelValue.slice(0, props.maxShow).reduce((acc, val, index) => {
        if (index === 0) return getLabel(val)
        return `${acc}, ${getLabel(val)}`
      }, '')
    })
    const valueStrPlus = computed(() => {
      if (props.modelValue.length === props.options.length || props.modelValue.length === 0) {
        return ''
      }
      return props.modelValue.length > props.maxShow ? `${props.modelValue.length - props.maxShow}` : ''
    })

    return {
      valueStr,
      valueStrPlus,
      wrapperClass: props.class,
    }
  },
})
