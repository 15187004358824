
import { defineComponent, ref } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import DropdownFilterAssignee from '@/components/shared/filters/dropdown/DropdownFilterAssignee.vue'
import { assigneeOptions } from '@/definitions/_general/_data/optionsList'

export default defineComponent({
  components: {
    TopFilter,
    DropdownFilterAssignee,
  },
  setup() {
    const search = ref('')
    const assignee = ref([])

    return {
      search,
      assignee,
      assigneeOptions,
    }
  },
})
