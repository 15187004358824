import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-732cac22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-center justify-between w100pr" }
const _hoisted_2 = { class: "d-flex overflow-hidden align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createBlock(_component_tm_button, { class: "tm-dropdown-filter-button" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _createVNode(_component_tm_icon, {
          name: "tmi-arrow-drop-down-sharp",
          class: "tm-dropdown-filter-button--toggle"
        })
      ])
    ]),
    _: 3
  }))
}