import { styleVars } from '@/compositions/styleVariables'
import type { ComputedRef, Ref } from 'vue'
import { onBeforeUnmount, onMounted, ref, computed } from 'vue'
import { kebabToPascalCase } from '@/services/utils'

const sizes = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', '3Xl', '4Xl']

type Rule = {
  name: string;
  rule: string;
}

type UseBreakpoints = () => {
  isXsMax: ComputedRef<boolean>
  isSmMax: ComputedRef<boolean>
  isMdMax: ComputedRef<boolean>
  isLgMax: ComputedRef<boolean>
  isXlMax: ComputedRef<boolean>
  isXXlMax: ComputedRef<boolean>
  is2XlMax: ComputedRef<boolean>
  is3XlMax: ComputedRef<boolean>
  is4XlMax: ComputedRef<boolean>
  isMdRange: ComputedRef<boolean>
  isLgRange: ComputedRef<boolean>
  isXlRange: ComputedRef<boolean>
  is2XlRange: ComputedRef<boolean>
  activeBreakpoints: Ref<string[]>
  breakpointRules: Rule[]
}

export const useBreakpoints: UseBreakpoints = () => {
  const breakpointRules: Rule[] = []
  const activeBreakpoints = ref<string[]>([])
  const matches = (rule: string) => window.matchMedia(rule).matches

  sizes.forEach((name: string) => {
    const rules = []
    const pascalName = kebabToPascalCase(name)
    const min = styleVars[`breakpoint${pascalName}Min`]
    const max = styleVars[`breakpoint${pascalName}Max`]

    if (min) rules.push({ name: `${name}-min`, rule: `(min-width: ${min}px)` })
    if (max) rules.push({ name: `${name}-max`, rule: `(max-width: ${max}px)` })
    if (min && max) rules.push({ name: `${name}-in-range`, rule: `(max-width: ${max}px) and (min-width: ${min}px)` })

    breakpointRules.push(...rules)
  })

  const onResize = () => {
    activeBreakpoints.value = breakpointRules
      .map((rule: Rule) => matches(rule.rule) ? rule.name : null)
      .filter((ruleName: string | null) => ruleName) as string[]
  }

  onMounted(() => {
    onResize()
    window.addEventListener('resize', onResize)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', onResize)
  })

  const is4XlMax = computed(() => activeBreakpoints.value.includes('4Xl-max'))
  const is3XlMax = computed(() => activeBreakpoints.value.includes('3Xl-max'))
  const is2XlMax = computed(() => activeBreakpoints.value.includes('xxl-max'))
  const isXXlMax = computed(() => activeBreakpoints.value.includes('xxl-max'))
  const isXlMax = computed(() => activeBreakpoints.value.includes('xl-max'))
  const isLgMax = computed(() => activeBreakpoints.value.includes('lg-max'))
  const isMdMax = computed(() => activeBreakpoints.value.includes('md-max'))
  const isSmMax = computed(() => activeBreakpoints.value.includes('sm-max'))
  const isXsMax = computed(() => activeBreakpoints.value.includes('xs-max'))

  const isMdRange = computed(() => activeBreakpoints.value.includes('md-in-range'))
  const isLgRange = computed(() => activeBreakpoints.value.includes('lg-in-range'))
  const isXlRange = computed(() => activeBreakpoints.value.includes('xl-in-range'))
  const is2XlRange = computed(() => activeBreakpoints.value.includes('xxl-in-range'))

  return {
    isXsMax,
    isSmMax,
    isMdMax,
    isLgMax,
    isXlMax,
    isXXlMax,
    is2XlMax,
    is3XlMax,
    is4XlMax,
    isMdRange,
    isLgRange,
    isXlRange,
    is2XlRange,
    activeBreakpoints,
    breakpointRules,
  }
}
