import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78a1aca5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-person" }
const _hoisted_2 = { class: "tm-person__avatar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_highlight = _resolveComponent("highlight")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "avatar", {}, () => [
        _createVNode(_component_tm_avatar, _mergeProps(_ctx.placeholderProps, {
          url: _ctx.avatarUrl,
          name: _ctx.name,
          color: _ctx.avatarColor,
          size: _ctx.avatarSize,
          status: _ctx.avatarStatus,
          "placeholder-custom-size": _ctx.avatarPlaceholderCustomSize,
          "custom-size": _ctx.avatarCustomSize
        }), null, 16, ["url", "name", "color", "size", "status", "placeholder-custom-size", "custom-size"])
      ], true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["tm-person__name", _ctx.semiBold && 'font-weight-semi-bold'])
    }, [
      _renderSlot(_ctx.$slots, "name", _normalizeProps(_guardReactiveProps({ name: _ctx.name })), () => [
        _createVNode(_component_highlight, {
          text: _ctx.name,
          search: _ctx.search
        }, null, 8, ["text", "search"])
      ], true)
    ], 2)
  ]))
}