
import { computed, defineComponent } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import Highlight from '@/components/shared/Highlight.vue'
import type { PropType } from '@vue/runtime-core'
import type { AvatarStatusVariant, AvatarColorVariant, AvatarSize, SizeProp } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmAvatar,
    Highlight,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    avatarStatus: {
      type: String as PropType<AvatarStatusVariant>,
    },
    avatarUrl: {
      type: String,
    },
    avatarColor: {
      type: String as PropType<AvatarColorVariant>,
    },
    avatarIcon: {
      type: String,
    },
    avatarSize: {
      type: String as SizeProp<AvatarSize>,
      default: 'medium',
    },
    avatarCustomSize: {
      type: String,
    },
    avatarPlaceholderCustomSize: {
      type: String,
    },
    semiBold: {
      type: Boolean,
    },
    search: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const placeholderProps = computed(() => {
      const obj: Record<string, any> = {}
      if (props.avatarIcon) {
        obj.placeholderType = 'icon'
        obj.customIcon = props.avatarIcon
      }
      return obj
    })

    return {
      placeholderProps,
    }
  },
})
