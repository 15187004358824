import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center overflow-hidden body-text-regular-14 overflow-hidden text-left text-overflow flex-grow-1 flex-basis-0 lh-1" }
const _hoisted_2 = { class: "body-text-semi-bold-14" }
const _hoisted_3 = { class: "ml-1 d-flex align-center lh-1 overflow-hidden" }
const _hoisted_4 = { class: "overflow-hidden flex-grow-0" }
const _hoisted_5 = {
  key: 0,
  class: "ml-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_ellipsis = _resolveComponent("tm-ellipsis")!
  const _component_tm_dropdown_filter_button = _resolveComponent("tm-dropdown-filter-button")!

  return (_openBlock(), _createBlock(_component_tm_dropdown_filter_button, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.name) + ":", 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_tm_ellipsis, { inline: "" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.value), 1)
              ]),
              _: 1
            })
          ]),
          (_ctx.valuePlus)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, "(+" + _toDisplayString(_ctx.valuePlus) + ")", 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}