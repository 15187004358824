import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fdd952a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "d-flex align-center" }
const _hoisted_3 = { class: "filter-checkbox-option__text" }
const _hoisted_4 = {
  key: 0,
  class: "light--text ml-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_file = _resolveComponent("tm-file")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_ellipsis = _resolveComponent("tm-ellipsis")!
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["filter-checkbox-option", { selected: _ctx.selected && !_ctx.disableHighlightSelected }])
  }, [
    _createVNode(_component_tm_field, {
      type: "checkbox",
      color: _ctx.item.checkboxColor ? _ctx.item.checkboxColor : '',
      "model-value": _ctx.selected,
      disable: _ctx.disable,
      "align-center": "",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue')))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "filter-checkbox-option__label",
          title: !_ctx.hideTooltip ? typeof _ctx.item === 'string' ? _ctx.item : _ctx.item[_ctx.optionLabel] : false
        }, [
          _renderSlot(_ctx.$slots, "option", _normalizeProps(_guardReactiveProps({ option: _ctx.item })), () => [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.item.circleBefore)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "filter-checkbox-option__circle",
                    style: _normalizeStyle(`background: ${_ctx.item.circleBefore}`)
                  }, null, 4))
                : _createCommentVNode("", true),
              (_ctx.item.fileType)
                ? (_openBlock(), _createBlock(_component_tm_file, {
                    key: 1,
                    class: "mr-2",
                    file: _ctx.item.fileType,
                    size: "small"
                  }, null, 8, ["file"]))
                : _createCommentVNode("", true),
              (_ctx.item.icon)
                ? (_openBlock(), _createBlock(_component_tm_icon, {
                    key: 2,
                    name: _ctx.item.icon,
                    class: _normalizeClass(["distinct--text mr-2", _ctx.item?.iconClass])
                  }, null, 8, ["name", "class"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_tm_ellipsis, { "min-width": "190px" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(typeof _ctx.item === 'string' ? _ctx.item : _ctx.item[_ctx.optionLabel]) + " ", 1),
                    (_ctx.item.description)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, "(" + _toDisplayString(_ctx.item.description) + ")", 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ])
            ])
          ], true)
        ], 8, _hoisted_1)
      ]),
      _: 3
    }, 8, ["color", "model-value", "disable"])
  ], 2))
}