
import { defineComponent, ref, computed } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import LoginHistoryTable from '@/components/pages/account/profile/LoginHistoryTable.vue'
import LoginHistoryFilter from '@/components/pages/account/profile/LoginHistoryFilter.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { TableItemType } from '@/definitions/account/profile/types'
import { getTableData } from '@/services/tableService'
import { formatDate } from '@/services/dateTimeService'

export default defineComponent({
  components: {
    TmModal,
    LoginHistoryTable,
    LoginHistoryFilter,
  },
  setup() {
    const historyHeaders = ref<TableHeaders[]>([
      { text: 'User', value: 'name' },
      { text: 'Country', value: 'country', width: '22%' },
      { text: 'IP', value: 'ip', hideSortable: true },
      { text: 'Login date', value: 'date-created', format: (val: string) => formatDate(val) },
    ])
    const history = ref<TableItemType[]>(getTableData('usersLoginHistory'))
    const search = ref('')
    const filteredTableItems = computed<TableItemType[]>(() => {
      return history.value.filter((item) => item.person?.fullName.toLowerCase().includes(search.value.toLowerCase()))
    })

    return {
      historyHeaders,
      filteredTableItems,
      search,
    }
  },
})
