
import { defineComponent } from 'vue'
import TmButtonMore from '@/components/shared/TmButtonMore.vue'
import type { ButtonSize, IconSize, SizeProp } from '@/definitions/shared/types'

export default defineComponent({
  components: { TmButtonMore },
  props: {
    btnSize: {
      type: String as SizeProp<ButtonSize>,
      default: 'medium',
    },
    iconSize: {
      type: String as SizeProp<IconSize>,
      default: 'xLarge',
    },
  },
})
