import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_checkbox = _resolveComponent("filter-checkbox")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, {
    class: _normalizeClass(_ctx.wrapperClass),
    "filter-dropdown": "",
    fit: !_ctx.noFit
  }, {
    default: _withCtx(({ internalValue }) => [
      _renderSlot(_ctx.$slots, "default", {
        value: _ctx.valueStr,
        valuePlus: _ctx.valueStrPlus,
        dropdownValue: internalValue
      }, () => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.panelView ? 'filter-panel-button' : 'filter-button'), {
          class: "mr-2",
          removable: !!_ctx.modelValue?.length && _ctx.removable,
          value: _ctx.valueStr,
          "value-plus": _ctx.valueStrPlus,
          name: _ctx.name,
          "value-class": _ctx.valueClass,
          icon: _ctx.icon,
          "btn-size": _ctx.btnSize,
          "all-text": _ctx.allText,
          "button-class": _ctx.buttonClass,
          onClear: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:model-value', [])))
        }, null, 40, ["removable", "value", "value-plus", "name", "value-class", "icon", "btn-size", "all-text", "button-class"]))
      ], true)
    ]),
    menu: _withCtx(({ close }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["dropdown-wrap", [
          { 'pt-4': _ctx.searchable},
          _ctx.dropdownClass,
        ]])
      }, [
        _renderSlot(_ctx.$slots, "filter", _normalizeProps(_guardReactiveProps({ close })), () => [
          _createVNode(_component_filter_checkbox, _mergeProps(_ctx.$attrs, {
            "model-value": _ctx.modelValue,
            options: _ctx.options,
            "option-label": _ctx.optionLabel,
            "options-wrap-height": 300,
            "custom-get-label": _ctx.customGetLabel,
            searchable: _ctx.searchable,
            onOnApply: close,
            onOnCancel: close,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:model-value', $event)))
          }), {
            option: _withCtx(({ option }) => [
              _renderSlot(_ctx.$slots, "option", _normalizeProps(_guardReactiveProps({ option })), undefined, true)
            ]),
            _: 2
          }, 1040, ["model-value", "options", "option-label", "custom-get-label", "searchable", "onOnApply", "onOnCancel"])
        ], true)
      ], 2)
    ]),
    _: 3
  }, 8, ["class", "fit"]))
}