
import { defineComponent } from 'vue'
import { formatDate } from '@/services/dateTimeService'
import TmCountry from '@/components/shared/TmCountry.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmChip from '@/components/shared/TmChip.vue'
import TmPerson from '@/components/shared/TmPerson.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'

export default defineComponent({
  components: {
    TmTableActionButton,
    TmDropdownItem,
    TmButton,
    TmChip,
    TmPerson,
    TmDropdown,
    TmTable,
    TmTooltip,
    TmCountry,
  },
  setup() {
    return {
      formatDate,
    }
  },
})
