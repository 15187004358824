import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_filter_assignee = _resolveComponent("dropdown-filter-assignee")!
  const _component_top_filter = _resolveComponent("top-filter")!

  return (_openBlock(), _createBlock(_component_top_filter, {
    ref: "topFilterRef",
    "hide-columns-additional-buttons": ""
  }, {
    "filter-line-left-default": _withCtx(() => [
      _createVNode(_component_dropdown_filter_assignee, {
        modelValue: _ctx.assignee,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.assignee) = $event)),
        options: _ctx.assigneeOptions,
        icon: "filter_alt",
        "search-placeholder": "Search user",
        "all-text": "All users",
        "btn-size": "large",
        "button-class": "font-weight-medium"
      }, null, 8, ["modelValue", "options"])
    ]),
    _: 1
  }, 512))
}