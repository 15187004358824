
import { defineComponent } from 'vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import TmDropdownFilterButton from '@/components/shared/dropdowns/TmDropdownFilterButton.vue'

export default defineComponent({
  components: { TmDropdownFilterButton, TmEllipsis },
  props: {
    value: {
      type: String,
    },
    valuePlus: {
      type: String,
    },
    name: {
      type: String,
    },
  },
  emits: ['update:modelValue'],
})
