
import { defineComponent } from 'vue'
import TmFile from '@/components/shared/TmFile.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'

export default defineComponent({
  name: 'FilterCheckboxOption',
  components: { TmEllipsis, TmFile },
  props: {
    selected: {
      type: Boolean,
    },
    disableHighlightSelected: {
      type: Boolean,
    },
    disable: {
      type: Boolean,
    },
    hideTooltip: {
      type: Boolean,
    },
    optionLabel: {
      type: String,
      default: 'label',
    },
    item: {
      type: [String, Object],
    },
  },
  emits: ['update:modelValue'],
})
