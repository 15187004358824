
import { computed, defineComponent, ref, watch } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmVirtualScroll from '@/components/shared/TmVirtualScroll.vue'
import FilterCheckboxOption from '@/components/shared/filters/FilterCheckboxOption.vue'
import FilterFooter from '@/components/shared/filters/FilterFooter.vue'
import FilterEmpty from '@/components/shared/filters/FilterEmpty.vue'
import TmSearch from '@/components/shared/TmSearch.vue'

export default defineComponent({
  name: 'FilterCheckbox',
  components: { FilterEmpty, FilterFooter, FilterCheckboxOption, TmVirtualScroll, TmButton, TmSearch },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    visibleMore: {
      type: Boolean,
    },
    optionKey: {
      type: String,
      default: 'id',
    },
    optionLabel: {
      type: String,
      default: 'label',
    },
    hideSelectBtns: {
      type: Boolean,
      default: false,
    },
    hideStateBtns: { // hot update modelValue
      type: Boolean,
      default: false,
    },
    disableHighlightSelected: {
      type: Boolean,
    },
    disableMenu: {
      type: Boolean,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    searchField: {
      type: String,
      default: '',
    },
    searchPlaceholder: {
      type: String,
      default: 'Search',
    },
    optionsWrapHeight: {
      type: Number,
    },
    customSearch: {
      type: Function,
    },
    hideTooltip: {
      type: Boolean,
      default: false,
    },
    customGetLabel: {
      type: Function,
    },
    updateModelValueOnClick: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'onApply', 'onCancel'],
  setup(props, context) {
    const query = ref('')
    const mutableValue = ref([...props.modelValue])
    const internalOptions = ref([...props.options])
    const filterArray = (arr: any) => {
      return arr.filter((item:any) => !item.subheader)
    }
    const selectAll = () => {
      mutableValue.value = filterArray([...internalOptions.value])
      if (props.hideStateBtns || props.updateModelValueOnClick) {
        context.emit('update:modelValue', filterArray(mutableValue.value))
      }
    }
    const deselectAll = () => {
      mutableValue.value = []
      if (props.hideStateBtns || props.updateModelValueOnClick) {
        context.emit('update:modelValue', filterArray(mutableValue.value))
      }
    }
    const onApply = async () => {
      context.emit('update:modelValue', filterArray(mutableValue.value))
      context.emit('onApply')
    }
    const onCancel = async () => {
      mutableValue.value = [...props.modelValue]
      query.value = ''
      context.emit('onCancel')
    }
    const searchFunc = (queryString: string, list: any[]) => {
      if (query.value) {
        return list.filter((item) => {
          const searchField = props.searchField || props.optionLabel
          return typeof item === 'string'
            ? item.toLowerCase().includes(queryString.toLowerCase())
            : item[searchField].toLowerCase().includes(queryString.toLowerCase())
        })
      }
      return internalOptions.value
    }
    const filteredOptions = computed<any[]>(() => {
      if (query.value) {
        return props.customSearch
          ? props.customSearch(query.value, filterArray(internalOptions.value))
          : searchFunc(query.value, filterArray(internalOptions.value))
      }
      return internalOptions.value
    })
    const isSelected = (item: any) => {
      if (typeof item === 'string') {
        return mutableValue.value.includes(item)
      }
      return mutableValue.value.some((el: any) => el[props.optionKey] === item[props.optionKey])
    }
    const onInput = (item: any) => {
      if (!isSelected(item)) {
        const values = [...mutableValue.value]
        values.push(item)
        mutableValue.value = values
      } else {
        mutableValue.value = mutableValue.value.filter((el: any) => {
          if (typeof item === 'string') {
            return el !== item
          } else {
            return el[props.optionKey] !== item[props.optionKey]
          }
        })
      }
      if (props.hideStateBtns || props.updateModelValueOnClick) {
        context.emit('update:modelValue', mutableValue.value)
      }
    }

    const showMoreState = ref({
      visibleField: false,
      value: null,
      visibleItems: [] as any[],
      input: async (val: any) => {
        internalOptions.value.push(val)
        mutableValue.value.push(val)
        showMoreState.value.visibleItems.push(val)
        context.emit('update:modelValue', mutableValue.value)
      },
      showField: () => {
        showMoreState.value.visibleField = true
      },
      showBtn: () => {
        showMoreState.value.visibleField = false
      },
    })

    watch(
      () => props.modelValue,
      () => {
        mutableValue.value = [...props.modelValue]
      }
    )

    return {
      mutableValue,
      query,
      filteredOptions,
      showMoreState,
      isSelected,
      onInput,
      onApply,
      onCancel,
      selectAll,
      deselectAll,
    }
  },
})
